@import "../../assets/sass/variables";
@import "../../assets/sass/button";

.user-page {
  background: linear-gradient(106.08deg, $purpleColor 0%, $darkBlue 55.46%);
  height: 100vh;
  .brand-title {
    color: $baseColor;
    font-family: Damion,cursive;
    font-size: 48px;
    line-height: 1;
  }
  a {
    color: $baseColor;
    font-family: Font-Medium;
  }

  &-container {
    width: 35%;
    gap: 10px;   
    .user-form {
      h2 {
        font-family: Font-Semibold;
        margin: 0px 0 40px;
      }
      h4{
        color: rgba($baseColor, 0.9);
      }
      p{
        color: rgba($baseColor, 0.8);
       }
      &-message {
        padding-bottom: 30px;
      }
      .ant-form-item {
        margin-bottom: 40px;
        .ant-input{
          font-size: 14px;
          height: 44px;
          color: $baseColor;
          background: rgba($baseColor, 0.2);
          border: 0px;
          padding:10px;
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);

          &:hover{
            background: rgba(255, 255, 255, 0.3);
          }

          &-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
            border: 0px;
            background: rgba($baseColor, 0.2);
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
            &:hover{
              background: rgba(255, 255, 255, 0.3);
            }
          }
        }
      }
      .ant-input-affix-wrapper {
        border: 0px;
        background: rgba($baseColor, 0.2);
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        padding: 0px 10px;

        .ant-input {
          background: transparent;
          padding: 0px;
          margin-top: 0px;
          box-shadow: 0px 0px 0px;
          &:hover{
            background: transparent;
          }
        }
        &:not(.ant-input-affix-wrapper-disabled):hover {
          border: 0px;
          background: rgba($baseColor, 0.2);
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        }
        &-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper{
          border: 0px;
            background: rgba($baseColor, 0.2);
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        }
        .anticon svg {
          fill:$baseColor;
        }
      }
    }
  }
  .login-button{
    background: linear-gradient(92.55deg, #1944B2 -1.23%, #2F76E0 -1.22%, #1944B3 97.14%);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: $baseColor;
    border: 0px;
    padding:0 30px;
    height:40px;
    font-family: Font-SemiBold;
    @extend %primary-btn;
    &:hover, &:focus{
      color: $baseColor;
      background: linear-gradient(92.32deg, #1944B2 2.22%, #2F76E0 99.82%, #1944B3 99.83%);
    }
  }
}
.login-content-modal {
  padding: 30px 90px;
}
.email-confirmation {
  svg {
    width: 53px;
    height: 45px;
  }
  h3 {
    margin-top: 20px;
    color: $tabdetail;
    font-family: Font-Semibold;
  }
}
.brand-logo{
  align-items: baseline;
  justify-content: flex-start;
  gap: 20px;
  &-img {
    height: 48px;
  }
}

.brand-title{
  color: $baseColor;
  font-size: 48px;
  font-family: 'Damion', cursive;
  line-height: 1;
  &-highlight{
    color:#E8AE55;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}
