@use "../../../assets/sass/variables";

.ask-question {
  display: flex;
  gap: 15px;
  height: calc(100vh - 180px);
  &.collapse {
    .ask-question-right-holder {
      flex-basis: 0;
      padding: 0;
      border-right: 0;
    }
  }
  .ant-btn-primary {
    background: transparent;
  }
  .record-search {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    .ant-input {
      height: 42px;
      font-size: 14px;
      &-search-button {
        height: 42px;
      }
    }
  }
  &-left-conatiner {
    flex: 1 1 70%;
    display: flex;
    flex-direction: column;
  }
  .ask-question-right-container {
    flex: 1;
    h4 {
      line-height: 1;
    }
  }
  .ask-question-icon {
    padding-top: 3px;
    width: 12px;
    height: 15px;
    align-self: center;
  }
  .overlap-icon {
    align-items: center;
    display: flex;
    height: 70px;
    position: absolute;
    left: -22px;
    top: 50%;
    width: 22px;
    border: 1px solid #e7ecf4;
    border-radius: 30px 0 0 30px;
    background: #fff;
    border-right: 0;
    justify-content: center;
  }
  &-right-holder {
    position: relative;
    flex-basis: 30%;
    gap: 10px;
    display: flex;
    align-items: baseline;
    transition: all 0.5s ease;
    border: 1px solid #e7ecf4;
    border-radius: 4px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
    padding: 20px;

    .question-item {
      padding: 12px;
      border-radius: 4px;
      background: rgba($color: #f5f5f6, $alpha: 0.4);
      &-list {
        max-height: calc(100vh - 340px);
        height: 100%;
        overflow-y: auto;
      }
    }
  }
  &-search-bar {
    margin-top: auto;
    &-container {
      grid-template-columns: 1fr 50px;
      gap: 10px;
      .ant-btn-icon-only.ant-btn-lg {
        border-radius: 4px;
      }
    }
  }
  &-result {
    &:not(:last-child) {
      margin-bottom: 30px;
      padding-right: 6px;
    }

    .question-icon {
      width: 32px;
      height: 32px;
      padding: 4px;
      border: 1px solid variables.$bgBaseColor;
      border-radius: 4px;
    }
    &-ques,
    &-answer {
      display: grid;
      grid-template-columns: 34px 1fr 60px;
      column-gap: 20px;
      padding: 16px 22px;
      // align-items: center;
    }
    &-ques {
      align-items: center;
    }
    &-answer {
      background: rgba($color: #f5f5f6, $alpha: 0.4);
      &-img {
        width: 32px;
        height: 32px;
        padding: 7px 6px;
        background: variables.$prussianBlueColor;
        border-radius: 4px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.chat-history-ques {
  padding: 12px 14px;
  background: rgba($color: #f5f5f6, $alpha: 0.4);
  border-radius: 3px;
  margin: 10px 0;
  h4 {
    color: variables.$darkBlue;
  }
}

.source {
  padding: 20px;
  max-height: calc(100vh - 175px);
  overflow: auto;
}

.source-list {
  margin-top: 6px;
  padding-left: 18px;
  max-height: 142px;
  overflow-y: auto;
  // &-anchor {
  //   margin-top: 6px;
  //   max-height: 142px;
  //   overflow-y: auto;
  // }
  li {
    list-style-type: disc;
    margin: 2px 0;
    padding-right: 20px;
    font-size: 12px;
    &.active {
      color: variables.$linkColor;
    }
  }
  &.gp-x-10 {
    column-gap: 10px;
  }
}
.source-content {
  margin: 20px 0 0;

  p {
    margin: 6px 0 20px 0;
    max-height: calc(100vh - 434px);
    overflow-y: auto;
    line-height: 20px;
  }

  a {
    font-family: font-bold, sans-serif;
  }
  .bg-yellow {
    background-color: yellow;
  }
}

.card-container {
  display: flex;
  position: relative;
  padding: 8px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.close-button {
  cursor: pointer;
}

.left-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 250px);
}

.project-select {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
