@import "../../assets/sass/variables";
@import "../../assets/sass/mixins";
.project-dashboard {
  padding: 15px 20px;

  .first-project {
    &-form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;

      .ant-form-item:last-child {
        padding-top: 0px;
      }
    }
  }
}

.grid-View {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.project-card-view {
  .ant-card-body{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  h5{
    line-height: 2;
  }
  .project-name {
    @include font_size_color(16px,$linkColor,null,null,Font-Semibold);
  }
  .ql-editor {
    padding: 12px 0px;
  }
  .highlight {
    @include font_size_color(14px,null,null,null,Font-Medium);
  }
  .active-check{
    margin-top: 3px;
    @include font_size_color(null,$green,null,null,Font-Bold);
  }
  .margin-top-auto{
    margin-top: auto;
  }
}

.view-control {
  opacity: 0.2;
  &.active {
    opacity: 1;
    color: $linkColor;
  }
}

.record-search {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  .ant-input {
    border-radius: 4px;
    height: 38px;
    background: transparent;
    color: $baseTextColor;
    border: 1px solid $borderColor;
    box-shadow: none !important;
    width: 100%;
    font-size: 12px;
    border-right: 0px;
  }
  .ant-input-search-button {
    height: 38px;
  }

  .ant-input-group-addon {
    background: transparent;
  }
  &.ant-input-search {
    .ant-input {
      &:hover,
      &:focus {
        border-color: $borderColor;
      }
    }
    & > .ant-input-group {
      & > .ant-input-group-addon:last-child {
        .ant-input-search-button {
          border-radius: 0 4px 4px 0;
          border-left: 0px;
          border-color: $borderColor;

          svg {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

