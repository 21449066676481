@use "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";

.ant-modal-wrap {
  position: relative;
  overflow: hidden;
  border-radius: 2px;

  .ant-modal-content {
    // // background: #36383e;
    // text-align: center;

    .ant-modal-header {
      border-bottom: none;
      padding-bottom: 0px;
    }

    .ant-modal-header-title {
      justify-content: end;

      svg {
        color: variables.$baseTextColor;
        font-size: 20px;
      }

      .ant-drawer-close {
        margin-right: 0px;
      }
    }
  }

  .modal-btn-group {
    margin-bottom: 30px;

    .ant-btn {
      font-size: 12px;
      //   background: transparent;
      color: variables.$baseTextColor;
      border: 1px solid variables.$borderColor;
      border-radius: 4px;
      padding: 2px 6px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    }
    .active {
      // background-color: variables.$linkColor;
      color: variables.$linkColor;
      border-color: variables.$borderColor;
    }
  }

  .ant-modal-body {
    // overflow-y: auto;
    // max-height: 175px;
    .drawer-close {
      a,
      button {
        font-size: 12px;
      }
      a {
        color: variables.$linkColor;
      }
    }
  }
}
