@use "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";

.tabs {
  .ant-tabs-top {
    > .ant-tabs-nav::before {
      border-bottom: 2px solid variables.$borderColor;
    }
  }
}

.result-pdf {
  &.ant-card {
    margin-bottom: 20px;
    padding: 20px;
    .slick-prev,
    .ant-carousel .slick-prev:hover {
      left: -10px;
    }
    .ant-carousel .slick-next,
    .ant-carousel .slick-next:hover {
      right: -10px;
    }
    .result-occurrences {
      text-align: center;
      color: variables.$baseTextColor;

      &-title {
        @include font_size_color(12px, variables.$titleColor, 600, null);
        .edit-icon {
          margin-left: auto;
          // font-weight: 400;
          cursor: pointer;
          color: variables.$linkColor;
        }
      }
    }
  }

  a {
    color: variables.$baseTextColor;
  }
}
/*--------Drawer-----------*/

.ant-carousel {
  font-size: 12px;
  margin-top: 20px;
  .slick-initialized .slick-slide {
    // margin:0 10px;
    > div {
      padding-right: 20px;
      padding-block: 17px;
    }
  }
}

.ant-carousel .carousel-arrow {
  padding: 0 15px;
}
.react-pdf__Page__canvas {
  border-radius: 6px;
  border: 1px solid variables.$borderColor;
}
