@use "../../assets/sass/variables";
@import "../../assets/sass/mixins";

.pagination {
  &.ant-pagination-simple .ant-pagination-simple-pager {
    font-size: 12px;
    input {
      font-family: Font-Bold;
      color: variables.$linkColor;
      width: 40px;
      background: rgba(28, 29, 34, 0.08);
      border-radius: 6px;
      padding: 0;
      border: 0px;
    }
  }
  &.ant-pagination-simple {
    .ant-pagination-next,
    .ant-pagination-prev {
      line-height: 30px;
    }
  }
}
