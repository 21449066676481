@import "../../assets/sass/variables";

.document-container {
  display: grid;
  height: calc(100vh - 250px);
}

.pdf-viewer {
  max-height: calc(100vh - 280px);
}

.view {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .ant-collapse {
    border-radius: 3px;
    background: $white;
    border: 0px solid $CardBorderColor;

    >.ant-collapse-item {
      border-bottom: 0px;
      border-radius: 3px;
      border: 1px solid $CardBorderColor;
      margin-bottom: 20px;
    }

    // &-item:not(:last-child){
    //   margin-bottom: 20px;
    // }
    &-item {
      &-active {
        .ant-collapse-header {
          background: $CardBorderColor;
        }
      }
    }

    &-header {
      border-radius: 2px 3px 0px 0px;
      // background: $CardBorderColor;
      border: 0px;

      &-text {
        font-family: "Font-Semibold", Arial, Sans-Serif;
      }
    }

    &-content {
      border-radius: 3px;
      border: 1px solid $CardBorderColor;
      background: $white;
      box-shadow: 0px 4px 15px 0px $boxShadowColor;
      border-top: 0px;
      padding: 26px 32px;

      .ant-collapse-content-box {
        padding: 0px;
      }
    }
  }
}

.upload {
  display: flex;
  gap: 20px;
  position: relative;
  &-name {
    display: flex;
    align-items: end;
    top: 145px;
    justify-content: center;
    right: 140px;

  }
}

.attachment {
  padding: 20px 30px 30px;

  svg {
    font-size: 52px;
    fill: $baseTextColor;
  }
}