
@import "../../assets/sass/variables";

.app-search {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    .ant-input{
    &-affix-wrapper{
        height: 38px;
        padding:0 6px;
        border: 1px solid $borderColor;
        background: $baseColor;
        border-radius: 4px;
            &:not(.ant-input-affix-wrapper-disabled):hover{
                border-color: $borderColor;
            }
            &-focused, &:focus{
                box-shadow: none;
            }
            .ant-input{
                box-shadow: none;
                &-suffix{
                    width: 1em;
                    margin-right: 5px;
                    svg{
                        opacity: 0.2;
                    }
                }
            }
        }
    }
  }
  