@use "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";
.e {
  &-content {
    &-row {
      p {
        @include font_size_color(20px, variables.$tabdetail, null, null, Font-semibold);
      }
      &-first,
      &-second,
      &-third {
        padding-bottom: 20px;
        margin: 20px 0;
        border-bottom: 1px solid variables.$borderColor;
        p {
          color: variables.$titleColor;
        }
        h5 {
          display: inline-block;
          margin-right: 12px;
          min-width: fit-content;
        }
        span {
          word-break: break-word;
        }
        > div {
          margin-top: 10px;
        }
      }
    }
  }
}

.multiple-search-result {
  &-title {
    h5,
    p {
      color: variables.$tabdetail;
    }
  }
  .ant-table {
    margin-top: 10px;
    padding: 0px;

    .ant-table-thead > tr > th {
      @include font_size_color(null, variables.$tabdetail, null, 1.2, Font-Semibold);
      padding: 0px 0px 8px 0;
      border-bottom: 0px;

      &:last-child {
        width: 33%;
      }
    }
    .ant-table-tbody > tr > td {
      padding: 6px;
      border-bottom: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:last-child {
        width: 30%;
      }
    }
    // .ant-table-container table .ant-table-cell:last-child {
    //   width: 33%;
    // }
  }
  .ant-table-row {
    background: variables.$tabBtnColor;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .selected-row {
    background: variables.$tabBtnFillColor;
    border-radius: 4px;
    & > td {
      @include font_size_color(null, variables.$linkColor !important, null, null, Font-Semibold);
    }
  }
}
