@use "../../assets/sass/variables";
@import "../../assets/sass/mixins";
.application {
    &-heading {
        @include font_size_color(16px, variables.$blackColor, null,null,Font-Semibold);
    }
    &-card{
        &.ant-card{
            padding:24px 30px;
            border-radius: 10px;
            border: 1px solid variables.$borderColor;
            .ant-card-body{
                display: grid;
                grid-template-columns: auto 70px 1fr auto;
            }
        }
        &-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap:30px
        }
        &-title {
            @include font_size_color(16px, variables.$linkColor, null,null,Font-Semibold);
            margin-bottom: 4px;
        }
    }
}