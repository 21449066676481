$primaryColor: #e8ae55;
$textPrimaryColor: #ffffff;
$textSecondaryColor: #9e9e9e;
$orange: #d87e14;
$red: #c2334d;
$blue: #4743db;
$green: #46D143;
$lightgray: #ededed;
$tabdetail: #1e293b;
$bgBaseColor: #222328;
$baseColor: #ffffff;
$baseTextColor: #475569;
$titleColor: #301934;
$smTitleColor: #02462d;
$linkColor: #3232A6;
$borderColor: #e7ecf4;
$CardBorderColor: #f5f9ff;
$tabBtnColor: #fcfcfc;
$tabBtnFillColor: #ebf1fa;
$selectRowColor: #f9fbff;
$blackColor: #0F172A;
$darkBlue: #270067;
$purpleColor:#8626C0;
$magnoliaWhite:#F9F7FC;
$btnGradient: linear-gradient(90deg, #3232a6 0%, #8626c0 100%);
$darkPurpleColor: #721ead;
$white: #fff;
$boxShadowColor: rgba(0, 0, 0, 0.05);
$prussianBlueColor: #410a63;
