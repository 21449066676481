@use "../sass/variables";

.ant-pagination {
  .ant-pagination-item,
  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link {
    background-color: transparent;
    border-radius: 4px;
    font-family: Font-Regular;
  }

  .ant-pagination-item a {
    color: variables.$baseTextColor;
    padding: 0px;
    font-size: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }
  .ant-pagination-item-active a {
    color: variables.$linkColor;
  }

  .ant-pagination-item-active {
    border-color: variables.$lightgray;
    // background: rgba(28, 29, 34, 0.08);
    border-radius: 4px;
    font-family: Font-Semibold;
  }

  .ant-pagination-item:hover {
    border-color: variables.$lightgray;
    background: rgba(28, 29, 34, 0.08);
    border-radius: 4px;
  }

  .ant-pagination-item:hover a {
    color: variables.$linkColor;
    font-family: Font-Medium;
  }
  .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link {
    border-color: transparent;
  }
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link {
    border-color: transparent;
    color: variables.$linkColor;
  }
  // .ant-pagination-next:hover .ant-pagination-item-link,
  // .ant-pagination-prev:hover .ant-pagination-item-link {
  //   color: variables.$primaryColor;
  //   border-color: variables.$primaryColor;
  // }
  .ant-pagination-next:hover svg {
    fill: variables.$linkColor;
  }

  .ant-pagination-next button,
  .ant-pagination-prev button {
    color: variables.$baseTextColor;
    font-size: 20px;
  }

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: variables.$linkColor;
  }

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: variables.$linkColor;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
  }

  .ant-select-arrow {
    color: #000;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 30px;
    font-size: 12px;
  }

  .ant-select-dropdown .ant-select-item-option {
    padding: 5px;
    text-align: left;
  }

  // .ant-pagination-disabled .ant-pagination-item-link,
  // .ant-pagination-disabled:hover .ant-pagination-item-link {
  //   .pagination-icon {
  //     color: variables.$lightgray;
  //   }
  // }

  .ant-pagination-disabled,
  .ant-pagination-disabled:hover {
    color: #a3aab4;
    opacity: 0.3;
  }

  .pagination-icon {
    color: #a3aab4;
  }
  .pagination-icon:hover {
    color: variables.$linkColor;
  }
}

.patients-tab {
  .ant-pagination {
    text-align: right;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
