@import "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";

.project-tab-content{
    padding:0 1.875em 1.875em;
}

.project-heading{
    background: $magnoliaWhite;
    padding: 1.875rem;
    padding-bottom: 0px;
    border-bottom: 1px solid rgba($textSecondaryColor, $alpha: 0.3);
    margin-bottom: 46px;
    &-text{
        font-size: 18px;
        font-family: "Font-Semibold", Arial, Sans-Serif;
        background: linear-gradient(90deg, $linkColor 1.69%, $purpleColor 101.55%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 15px;
    }
    .ant-tabs-top{
        &>.ant-tabs-nav{
            margin-bottom: 0px;
        }
    }
}

.summary-grid{
    display: grid;
    grid-template-columns: 1fr 320px;
    column-gap: 30px;
    .active-check{
        @include font_size_color(null,$green,null,null,Font-Bold);
      }

      .collabarators{
        h3{
            padding:20px;
            background: linear-gradient(106.08deg, $purpleColor 0%, $darkBlue 55.46%);
            border-radius: 4px 4px 0px 0px;
            @include font_size_color(null,$baseColor,null,null,Font-Semibold);
        }
        &-list{
            border-radius: 0px 0px 4px 4px;
            background: $magnoliaWhite;
            border: 1px solid rgba($textSecondaryColor, $alpha: 0.2);
            box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
        }
      }
}