@use "../../../assets/sass/variables";
@import "../../../assets/sass/checkbox";

.legends {
  padding-top: 6px;
  margin-right: 1.5rem;
  // margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  .ant-checkbox + span {
    padding-left: 0px;
  }

  .ant-checkbox-group {
    padding-left: 8px;
    &-item {
      margin-right: 0px;
    }
  }

  &-item {
    border-radius: 3px;
    padding: 3px 8px 3px 30px;
    // margin-left: -22px;
    // margin-right: 20px;
    margin: 0 20px 10px -22px;
  }
}
